import React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import pages from '../utility/pages';
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
const { slugify, filterByLang } = require('../utility/utils');

const Blog = ({ pageContext: { locale } })  => {
  const { allContentfulArticoliBlog } = useStaticQuery(
    graphql`
      query {
        allContentfulArticoliBlog(sort: {fields: data, order: DESC}) {
          edges {
            node {
              contentful_id
              node_locale
              titolo
              abstract
              copertina {
                title
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              } 
              testo {
                childMarkdownRemark {
                  excerpt (pruneLength: 200)
                }
              } 
              categoria
              data(formatString: "DD MMMM, YYYY", locale:"it_IT")
            }
          }
        }
      }
    `
  ); 
  const posts = filterByLang(allContentfulArticoliBlog,locale);

  return (
    <Layout locale={locale}>
      <SEO title="Blog" />
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="pagetitle">Blog</h1>
        </div>
      </div>
     
      <div className="section section--top blog">

        <div className="row equal">
          {posts.map((item,index) => { 
            return (
              <div key={index} className="col-sm-12 col-md-6 col-lg-4 d-flex">
                <div className="blog__card">
                  {!!item.copertina  &&
                  <Link  to={`${pages[locale]('/blog/')}${slugify(item.titolo)}/`}>
                    <Img alt={item.titolo} title={item.titolo} className="blog__copertina" fluid={item.copertina.fluid} />
                  </Link>  
                  }
                  <div className="p-3">
                    <div className="blog__title">
                      <Link to={`${pages[locale]('/blog/')}${slugify(item.titolo)}/`}>{item.titolo}</Link>
                    </div> 
                    <div className="blog__date">
                      {item.data}
                    </div>
                    <div className="blog__text"  dangerouslySetInnerHTML={{__html: item.testo.childMarkdownRemark.excerpt}} />
                    <div className="text-center blog__read">
                      <Link to={`${pages[locale]('/blog/')}${slugify(item.titolo)}/`} className="btn-action btn-action--small">Leggi</Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    </Layout>
  )
}
export default Blog
